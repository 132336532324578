import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWebpImage from 'vue-webp-image'
import VueLazyLoad from 'vue3-lazyload'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {faCircleArrowDown} from '@fortawesome/free-solid-svg-icons'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {faXmark} from '@fortawesome/free-solid-svg-icons'

library.add(faXmark)
library.add(faCircleArrowDown)
library.add(faAngleRight)
createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(store).use(router).use(VueWebpImage).use(VueLazyLoad).mount('#app')
