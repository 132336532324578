<template>
  <transition name="slide-fade">
    <div class="form-message" :class="{error:!$store.state.statusSend}" v-if="$store.state.messageShow">
      <div v-if="$store.state.statusSend">Мы получили ваше сообщение и свяжемся с вами в ближайшее время!</div>
      <div v-else>Что-то пошло не так, попробуйте позже :(</div>
    </div>
  </transition>
  <transition name="slide-fade">
    <div class="form-message error" v-if="$store.state.errorForm">
      Мы не сможем связаться с вами, если вы не оставите контактные данные
    </div>
  </transition>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">

@font-face {
  font-family: 'Museo';
  src: url("~@/assets/fonts/Museo/Museo_Cyrillic.otf") format('opentype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input {
  border: 1px solid black;
  border-radius: 0;
  outline: none;
}

html {
  font-family: 'Museo';
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000;
}

h1, h2, h3, h4, h5 {
  font-size: unset;
  font-weight: normal;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.form-message {
  position: fixed;
  z-index: 999;
  background-color: rgba(47, 216, 182, 0.66);
  padding: rem(20);
  font-weight: 600;
  color: white;
  bottom: 0;
  right: 0;
}

.error {
  background-color: #FF000078;
}

.midnightHeader.light {
  .logo {
    color: white;
  }

  .menu-icon path {
    fill: white;
  }
}

.midnightHeader.dark {
  .logo {
    color: black;
  }

  .menu-icon path {
    fill: black;
  }
}

.midnightHeader.middle {
  .logo {
    color: black;
  }

  .menu-icon path {
    fill: white;
  }
}
</style>
