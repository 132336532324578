import {createStore} from 'vuex'
import axios from "axios";

export default createStore({
    state: {
        showMenu: false,
        messageShow: false,
        statusSend: false,
        errorForm: false,
        websites: [
            {
                pictures: 'https://ed-webdev.pro/images/websites/rushev.webp',
                src: 'https://rushev.online/',
                description: 'Сайт модных украшений бренда RUSHEV'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/keramMarket.webp',
                src: 'https://www.keram-market.ru/',
                description: 'Сайт по продаже керамических изделий с возможностью 3D моделирования',
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/becompact.webp',
                src: 'https://becompact.ru/',
                description: 'Интернет магазин, оформленный в стиле маркетплейсов',
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/eteapparel.webp',
                src: 'https://eteapparel.com/',
                description: 'Стильный интернет магазин по продаже одежды'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/avtoto.webp',
                src: 'https://www.avtoto.ru/',
                description: 'Одна из крупнейших компаний по продаже автомобильных запчастей'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/sousbuy.webp',
                src: 'https://sous-buy.ru/',
                description: 'Площадка по продаже различных игр'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/homeme.webp',
                src: 'https://www.homeme.ru/',
                description: 'Интернет магазин недорогой мебели в Москве'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/lapotknn.webp',
                src: 'https://lapotoknn.ru/',
                description: 'Сайт по продаже обуви и различных аксессуаров'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/kusi.webp',
                src: 'https://kusi.ru/',
                description: 'Онлайн магазин зоотоваров'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/noblesnob.webp',
                src: 'https://www.noblesnob.com/',
                description: 'Онлайн магазин брендовой одежды'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/imperia.webp',
                src: 'https://imperiaparketa.ru/',
                description: 'Магазин напольных покрытий'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/lionesswear.webp',
                src: 'https://lionesswear.com/',
                description: 'Онлайн магазин пижамных изделий'
            },
            {
                pictures: 'https://ed-webdev.pro/images/websites/giventtie.webp',
                src: 'https://giventtie.com/',
                description: 'Онлайн магазин ювелирных украшений'
            }
        ],
        landings: [
            {
                pictures: 'https://ed-webdev.pro/images/landings/54proreality.webp',
                src: 'https://54pro-realty.ru/',
                description: 'Сервис бронирования новостроек'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/superfit.webp',
                src: 'https://superfit.lpmotortest.com/',
                description: 'Онлайн марафон по похудению'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/mysanta.webp',
                src: 'https://mysanta.ru/',
                description: 'Создание новогодней игры "Мой санта"'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/airbnb.webp',
                src: 'https://www.airbnb.ru/',
                description: 'Сервис по создания мероприятий'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/vozduh.webp',
                src: 'https://vozduh-studio.ru/',
                description: 'Сайт танцевальной школы'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/fauxpas.webp',
                src: 'https://fauxpas.ru/',
                description: 'Лучшая тату студия в Восточной Европе'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/spicytattoing.webp',
                src: 'https://spicytattooing.ru/',
                description: 'Сайт мастеров по татуажу'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/scenapro.webp',
                src: 'https://scenapro.ru/lp/scenicheskie-kompleksy-2/',
                description: 'Создание сценических комплексов под ключ'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/dilektpro.webp',
                src: 'https://dilektwood.ru/',
                description: 'Производство изделий из дерева'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/terrazzo.webp',
                src: 'https://xn--80aj2agaj6aa.xn--p1ai/',
                description: 'Производство изделий из Терраццо'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/stone.webp',
                src: 'https://c-stone.ru/',
                description: 'Производство гибких покрытий'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/molniya.webp',
                src: 'https://katerinamolniya.ru/',
                description: 'Сайт-презентация Екатерины Молнии'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/afina.webp',
                src: 'https://afinamanakova.ru/',
                description: 'Сайт художественной школы Афины Манаковой'
            },
            {
                pictures: 'https://ed-webdev.pro/images/landings/unipest.webp',
                src: 'https://unipest.ru/',
                description: 'Сайт компании по дезинфекции'
            }
        ],
        corp: [
            {
                pictures: 'https://ed-webdev.pro/images/corp/loft.webp',
                src: 'https://greenandwhiteloft.com/',
                description: 'Аренда лофтов в Москве'
            },
            {
                pictures: 'https://ed-webdev.pro/images/corp/tpk.webp',
                src: 'http://tpk-tt.ru/',
                description: 'Производство оборудования для электропоездов'
            },
            {
                pictures: 'https://ed-webdev.pro/images/corp/kjgroup.webp',
                src: 'https://kj-group.ru/',
                description: 'Архитектурное агентство'
            },
            {
                pictures: 'https://ed-webdev.pro/images/corp/tdkgroup.webp',
                src: 'https://tdkgroup.ru/',
                description: 'Магазин мозаичной плитки'
            },
            {
                pictures: 'https://ed-webdev.pro/images/corp/narvskaya.webp',
                src: 'https://narvskaya-zastava.ru/',
                description: 'Сайт музея Нарвская Застава'
            }
        ]
    },
    getters: {},
    mutations: {
        SET_SHOWMENU(state) {
            state.showMenu = !state.showMenu
        },
        SET_SHOWMESSAGE(state) {
            state.messageShow = true;
            setTimeout(() => {
                state.messageShow = false;
            }, 3000)
        },
        SET_FORMERROR(state) {
            state.errorForm = true;
            setTimeout(() => {
                state.errorForm = false;
            }, 3000)
        },
        SET_SENDSTATUS(state, status) {
            state.statusSend = status;
        }
    },
    actions: {
        upload_scripts() {
            const script1 = document.createElement('script');
            const script2 = document.createElement('script');
            script1.setAttribute('src', 'https://ed-webdev.pro/solutions/midnight.jquery.min.js');
            script2.innerText = '$(\'header\').midnight();';
            script1.setAttribute('class', 'mid');
            script2.setAttribute('class', 'mid');
            document.body.appendChild(script1);
            setTimeout(() => {
                document.body.appendChild(script2);
            }, 2000);
        },
        check_scripts() {
            const scripts = document.querySelectorAll('.mid');
            if (scripts.length == 0) {
                this.dispatch('upload_scripts');
            } else {
                scripts.forEach(elem => {
                    elem.remove();
                });
                this.dispatch('upload_scripts');
            }
        },
        async PostRequest({commit}, message) {
            try {
                const response = await axios.post(message.url, {
                    name: message.name,
                    contact: message.contact,
                    text: message.text
                });
                commit('SET_SENDSTATUS', response.data.ok)
                commit('SET_SHOWMESSAGE');
            } catch (e) {
                console.log('Error!!!');
            }
        }
    }
})
