import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import('@/pages/Home.vue')
const AboutMe = () => import('@/pages/AboutMe')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: AboutMe
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})

export default router
